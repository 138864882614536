import { REACT_APP_BASE_URL } from '../consts'
import request from './request'

export default async (data) => {
  const ans = await request({
    url: `${REACT_APP_BASE_URL}/verify-user-details`,
    method: 'post',
    data
  })
  return ans
}
