import InterRegularFont from './Inter-Regular.ttf'
import InterSemiBoldFont from './Inter-SemiBold.ttf'
import InterBoldFont from './Inter-Bold.ttf'
import MuliRegulrarFont from './Muli-Regular.ttf'
import MuliBoldFont from './Muli-Bold.ttf'
import ArimoHeRegulrarFont from './Arimo-Regular.ttf'
import ArimoHeBoldFont from './Arimo-Bold.ttf'

const interRegular = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  src: `
    local('Inter'),
    local('Inter-Regular),
    url(${InterRegularFont}) format('ttf') 
    `,
}

const interSemiBold = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  src: `
      local('Inter'),
      local('Inter-SemiBold),
      url(${InterSemiBoldFont}) format('ttf') 
      `,
}

const interBold = {
  fontFamily: 'Inter-Bold',
  fontStyle: 'bold',
  src: `
      local('Inter'),
      local('Inter-Bold),
      url(${InterBoldFont}) format('ttf') 
      `,
}

const muliRegular = {
  fontFamily: 'Muli',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    url(${MuliRegulrarFont}) format('truetype')
  `,
  unicodeRange:
    // eslint-disable-next-line max-len
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

const muliBold = {
  fontFamily: 'Muli',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    url(${MuliBoldFont}) format('truetype')
  `,
  unicodeRange:
    // eslint-disable-next-line max-len
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

const arimoHeRegulrar = {
  fontFamily: 'Arimo',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    url(${ArimoHeRegulrarFont}) format('truetype')
  `,
  unicodeRange:
    'U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F',
}

const arimoHeBold = {
  fontFamily: 'Arimo',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    url(${ArimoHeBoldFont}) format('truetype')
  `,
  unicodeRange:
    'U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F',
}

export default {
  interRegular,
  interSemiBold,
  interBold,
  muliRegular,
  muliBold,
  arimoHeRegulrar,
  arimoHeBold
}
