/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import parentComms from '../../lib/parentComms'

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,

    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    minWidth: '100%',
    minHeight: '100%',

    zIndex: 2147483647,

    animation: '$appear 300ms linear',
    animationIterationCount: 1,
    animationFillMode: 'forwards !important',
    opacity: 0,
    backgroundColor: 'rgba(0, 0, 0, 1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  everybyteLogo: {
    fontSize: 50,
    fontWeight: 'bold',
    textAlign: 'center',
    letterSpacing: 3,
    color: 'white',
  },
  mainText: {
    textAlign: 'center',
    color: 'white',
    fontSize: 15,
    marginTop: 10,
    marginBottom: 10,
  },
  textButton: {
    fontWeight: 'bold',
    textAlign: 'center',
    color: 'white',
    fontSize: 15,
    marginTop: 10,
    marginBottom: 10,
    textDecoration: 'underline',
  },
  container: {
    maxWidth: 350,
    display: 'flex',
    cursor: 'pointer',
    alignContent: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  closeButton: {
    position: 'absolute',
    color: 'white',
    top: 16,
    right: 16,
    width: 23,
    height: 23,
  },
  '@keyframes appear': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
}), { name: 'Waiting' })

const Waiting = (props) => {
  const classes = useStyles(props)
  useEffect(() => {
    parentComms('waiting', 'hello from here')
  }, [])

  const close = () => {
    parentComms('waiting', 'abort')
    parentComms('waiting', 'close')
  }

  const focus = () => {
    parentComms('waiting', 'focus')
  }

  return (
    <div className={classes.root}>
      <div className={classes.container} onClick={focus} role="button" tabIndex="0">
        <div className={classes.everybyteLogo}>
          EVERYBYTE
        </div>
        <div className={classes.mainText}>
          Don’t see the secure Everybyte browser? We’ll help you re-launch the window to complete your login
        </div>
        <div className={classes.textButton}>
          Click here to continue
        </div>
      </div>
      <IconButton className={classes.closeButton} onClick={close}>
        <CloseIcon />
      </IconButton>
    </div>
  )
}

export default Waiting
