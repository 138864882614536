import React from 'react'
import TwoStep from '../../Components/Login/TwoStep'
import getQueryStringValue from '../../lib/getQueryStringValue'

const TwoStepVerification = () => (
  <div>
    <TwoStep
      sessionId={getQueryStringValue('sessionId')}
    />
  </div>
)

export default TwoStepVerification
