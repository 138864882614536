import React from 'react'
import PlaidConnector from '../../Components/Plaid/PlaidConnector'
import getQueryStringValue from '../../lib/getQueryStringValue'

const Plaid = () => (
  <div>
    <PlaidConnector
      sessionId={getQueryStringValue('sessionId')}
    />
  </div>
)

export default Plaid
