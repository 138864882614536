import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Redirect, Route, Switch } from 'react-router-dom'
import Login from './Pages/Login/Login'
import Waiting from './Pages/WaitingScreen/WaitingScreen'
import Temp from './Pages/TempScreen/TempScreen'
import Plaid from './Pages/Plaid/Plaid'
import TwoStepVerification from './Pages/Login/TwoStep'

const useStyles = makeStyles(() => ({
  root: {
    display: 'block',
  },
}), { name: 'MainRouter' })

export default function MainRouter() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Switch>
        <Route exact path="/">
          <Login />
        </Route>
        <Route exact path="/plaid">
          <Plaid />
        </Route>
        <Route exact path="/two-step">
          <TwoStepVerification />
        </Route>
        <Route exact path="/waiting">
          <Waiting />
        </Route>
        <Route exact path="/temp">
          <Temp />
        </Route>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    </div>
  )
}
