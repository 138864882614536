import axios from 'axios'

export default async (params) => {
  try {
    const {
      data,
    } = await axios(params)

    return data
  }
  catch (e) {
    throw new Error(e)
  }
}
