export default (source, type, payload = {}) => {
  switch (source) {
    case 'auth':
      window.opener?.postMessage({ source, type, payload }, '*')
      break
    case 'waiting':
      window.parent?.postMessage({ source, type, payload }, '*')
      break
    default:
  }
}
