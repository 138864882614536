import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Main from '../../Components/Login/Main'
import parentComms from '../../lib/parentComms'
import getQueryStringValue from '../../lib/getQueryStringValue'

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '100%',
  },
}), { name: 'Login' })

const Login = (props) => {
  const classes = useStyles(props)
  useEffect(() => {
    parentComms('auth', 'hello')
    window.onunload = () => {
      parentComms('auth', 'close')
    }
  }, [])

  return (
    <div className={classes.container}>
      <Main
        sessionId={getQueryStringValue('sessionId')}
      />
    </div>
  )
}

export default Login
