import React from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { BrowserRouter as Router } from 'react-router-dom'
import theme from './lib/theme'
import App from './App'
import Snackbar from './Components/Snackbar'

function AppProviders() {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Snackbar>
        <Router>
          <App />
        </Router>
      </Snackbar>
    </MuiThemeProvider>
  )
}

export default AppProviders
