import { createMuiTheme } from '@material-ui/core'
import fonts from '../assets/fonts'

const {
  interRegular,
  interSemiBold,
  interBold,
  muliRegular,
  muliBold,
  arimoHeRegulrar,
  arimoHeBold,
} = fonts

export default createMuiTheme({
  palette: {
    primary: {
      main: '#112638',
    },
    secondary: {
      main: '#112638',
    },
    background: {
      paper: '#fffff',
      default: '#fffff',
    },
  },
  typography: {
    fontFamily: 'Inter, Muli, Arimo, sans-serif',
  },
  overrides: {
    MuiPaper: {
      root: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      containedSecondary: {
        color: 'white',
      },
    },
    MuiCssBaseline: {
      '@global': {
        '*': {
          outline: 'none',
        },
        '#gtx-trans': { // hide google translate widget on text selection
          display: 'none',
        },
        '@font-face': [
          interRegular,
          interSemiBold,
          interBold,
          muliRegular,
          muliBold,
          arimoHeRegulrar,
          arimoHeBold,
        ],
        body: {
          margin: 0,
        },
      },
    },
  },
})
