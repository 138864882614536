import React, { useState, useEffect } from 'react'
// eslint-disable-next-line import/no-unresolved
import SweetAlert from 'react-bootstrap-sweetalert'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { useSnackbar } from 'notistack'
import TextField from '@material-ui/core/TextField'
import LoaderGabi from '../LoaderGabi'
import parentComms from '../../lib/parentComms'
import { request, verifyUserDetails } from '../../api'
import logo from '../../assets/icons/everybyte-logo.png'
import { REACT_APP_BASE_URL } from '../../consts'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      fontWeight: 'bold',
      fontSize: '1rem',
    },
    container: {
      minWidth: 400,
    },
    dividerContainer: {
      display: 'flex',
      alignItems: 'center',
      opacity: 0.5,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(),
      width: '100%',
    },
    border: {
      opacity: 0.5,
      borderBottom: '1px solid black',
      width: '100%',
    },
    content: {
      padding: '0 10px 0 10px',
    },
    text: {
      margin: theme.spacing(1),
    },
    logo: {
      maxWidth: '100%',
      margin: theme.spacing(3)
    }
  }),
  { name: 'TwoStep' }
)

const TwoStep = (props) => {
  const classes = useStyles()
  const [answer, setAnswer] = useState('')
  const [done, setDone] = useState(false)
  const [loading, setLoading] = useState(true)
  const [transaction, setTransaction] = useState({})
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const { sessionId } = props

  useEffect(() => {
    if (!sessionId) return

    const fetchTransaction = async () => {
      const userTransaction = await request({
        url: `${REACT_APP_BASE_URL}/get-transaction`,
        method: 'post',
        data: {
          sessionId,
        },
      })

      setLoading(false)
      setTransaction(userTransaction)
    }

    fetchTransaction()
  }, [sessionId])

  const submitAnswer = async (event) => {
    event.preventDefault()

    try {
      setLoading(true)
      await request({
        url: `${REACT_APP_BASE_URL}/verify-answer`,
        method: 'post',
        data: {
          answer,
          transactionId: transaction.transactionId,
          sessionId,
        },
      })

      const data = await verifyUserDetails({
        sessionId,
      })

      const { validatedFields } = data

      // enqueueSnackbar('Connected Successfully', { variant: 'success' })
      setLoading(false)
      setDone(true)
      setTimeout(() => {
        parentComms('auth', 'approve', validatedFields)
        parentComms('auth', 'close')
      }, 2000)
    }
    catch (e) {
      enqueueSnackbar('Failed to log in', { variant: 'error' })
      parentComms('auth', 'failed', e)
    }
  }

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo} alt="" className={classes.logo} />
        <div className={classes.text}>
          <Typography>Provide an answer from your bank account</Typography>
        </div>
        {loading ? (
          <LoaderGabi />
        ) : (
          <div>
            <div className={classes.text}>
              <Typography>
                {`What is the amount of the transaction you made at ${transaction.name} on
                ${transaction.date}?`}
              </Typography>
            </div>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                color="secondary"
                fullWidth
                id="twoStep"
                label="Transaction Amount"
                name="answer"
                autoFocus
                value={answer}
                onInput={(e) => setAnswer(e.target.value)}
              />
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                className={classes.submit}
                onClick={submitAnswer}
              >
                Continue
              </Button>
              <div className={classes.dividerContainer}>
                <div className={classes.border} />
                <span className={classes.content}>OR</span>
                <div className={classes.border} />
              </div>
              <Button
                fullWidth
                variant="outlined"
                color="secondary"
                className={classes.submit}
                onClick={() => history.push(`/?sessionId=${sessionId}`)}
              >
                Go back
              </Button>
            </form>
          </div>
        )}
      </div>
      {done && (
        <SweetAlert
          success
          title="Success!"
          onConfirm={() => { }}
          customButtons={(
            <>
            </>
          )}
        >
          You can now close this window and go back!
        </SweetAlert>
      )}
    </Container>
  )
}

export default TwoStep
