import React, { useState } from 'react'
// eslint-disable-next-line import/no-unresolved
import SweetAlert from 'react-bootstrap-sweetalert'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { useSnackbar } from 'notistack'
import OtpInput from 'react-otp-input'
import { otpValidation, verifyUserDetails } from '../../api'
import parentComms from '../../lib/parentComms'

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  otpContainer: {
    marginTop: theme.spacing(3),
    marginButtom: theme.spacing(3),
  },
  otp: {
    width: '3rem !important',
    height: '3rem',
    margin: '0 0.5rem',
    fontSize: '2rem',
    borderRadius: '4px',
    border: '1px solid rgba(0,0,0,0.3)',
  },
  container: {
    minWidth: 400,
  },
  dividerContainer: {
    display: 'flex',
    alignItems: 'center',
    opacity: 0.5,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(),
    width: '100%',
  },
  border: {
    opacity: 0.5,
    borderBottom: '1px solid black',
    width: '100%',
  },
  content: {
    padding: '0 10px 0 10px',
  },
  disclaimer: {
    textAlign: 'center',
    opacity: 0.7,
    marginTop: theme.spacing(2),
  },
  text: {
    textDecoration: 'underline',
    cursor: 'pointer',
    marginButtom: theme.spacing(),
  },
}), { name: 'Otp' })

const Otp = (props) => {
  const classes = useStyles()
  const [otp, setOtp] = useState('')
  const [done, setDone] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const {
    identifier,
    connection,
    setOtpOpen,
    sessionId,
    isSignUp,
  } = props

  const otpLogin = async (code) => {
    setOtp(code)
  }

  const submitOtp = async () => {
    if (otp.length === 6) {
      try {
        await otpValidation({
          code: otp,
          target: identifier,
          connection,
          sessionId,
        })

        if (isSignUp) {
          const data = await verifyUserDetails({
            sessionId,
          })

          console.log('data', data)

          // enqueueSnackbar('Connected Successfully', { variant: 'success' })
          setDone(true)

          history.push(`/two-step?sessionId=${sessionId}`)
        }
        else {
          history.push(`/two-step?sessionId=${sessionId}`)
        }
      }
      catch (e) {
        enqueueSnackbar('Wrong verification code', { variant: 'error' })
        parentComms('auth', 'failed', e)
      }
    }
    else {
      enqueueSnackbar('OTP should contain 6 digits', { variant: 'warning' })
    }
  }

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <CssBaseline />
      <div className={classes.paper}>
        <Typography>
          Insert OTP
        </Typography>
        <OtpInput
          value={otp}
          onChange={otpLogin}
          numInputs={6}
          containerStyle={classes.otpContainer}
          separator={<span>-</span>}
          inputStyle={classes.otp}
        />
        <Typography className={classes.disclaimer}>
          Did not get an OTP?
        </Typography>
        <Typography className={classes.text}>
          Click here to resend
        </Typography>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          className={classes.submit}
          onClick={() => submitOtp()}
        >
          Submit
        </Button>
        <div className={classes.dividerContainer}>
          <div className={classes.border} />
          <span className={classes.content}>
            OR
          </span>
          <div className={classes.border} />
        </div>
        <Button
          fullWidth
          variant="outlined"
          color="secondary"
          className={classes.submit}
          onClick={() => setOtpOpen(false)}
        >
          Go back
        </Button>
      </div>
      {done && (
        <SweetAlert
          success
          title="Success!"
          onConfirm={() => { }}
          customButtons={(
            <>
            </>
          )}
        >
          You can now close this window and go back!
        </SweetAlert>
      )}
    </Container>
  )
}

export default Otp
