const {
  NODE_ENV,
  REACT_APP_BASE_URL = '',
  REACT_APP_PLAID_ENVIRONMENT = ''
} = process.env

export {
  NODE_ENV,
  REACT_APP_BASE_URL,
  REACT_APP_PLAID_ENVIRONMENT,
}
