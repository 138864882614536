/* eslint-disable no-await-in-loop */
/* eslint-disable import/no-unresolved */
import React, { useCallback, useEffect, useState } from 'react'
import { v4 } from 'uuid'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import {
  Button,
  Container,
  CssBaseline,
  Typography,
} from '@material-ui/core'
import { PlaidLink } from 'react-plaid-link'
import { useSnackbar } from 'notistack'
import Otp from '../Login/Otp'
import LoaderGabi from '../LoaderGabi'
import { request, otpRequest } from '../../api'
import parentComms from '../../lib/parentComms'
import logo from '../../assets/icons/everybyte-logo.png'
import { REACT_APP_BASE_URL, REACT_APP_PLAID_ENVIRONMENT } from '../../consts'

const plaidUserId = v4()

const plaidBody = {
  userId: plaidUserId,
  clientName: 'Everybyte',
  products: ['transactions'],
  countryCodes: ['US'],
  language: 'en',
  webhook: REACT_APP_BASE_URL || 'http://localhost:3000',
}

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: 400,
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    fontSize: '3rem',
    margin: theme.spacing(1),
  },
  plaidButton: {
    width: '100%',
    marginTop: theme.spacing(2),
    fontSize: '1rem',
    fontWeight: 'bold',
    color: 'white',
    cursor: 'pointer',
    backgroundColor: `${theme.palette.secondary.main} !important`,
    border: '1px solid rgba(17, 38, 56, 0.5) !important',
    padding: '5px 15px !important'
  },
  dividerContainer: {
    display: 'flex',
    alignItems: 'center',
    opacity: 0.5,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(),
    width: '100%',
  },
  border: {
    opacity: 0.5,
    borderBottom: '1px solid black',
    width: '100%',
  },
  content: {
    padding: '0 10px 0 10px',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  disclaimer: {
    textAlign: 'center',
    opacity: 0.7,
    marginTop: theme.spacing(2),
  },
  text: {
    textDecoration: 'underline',
    cursor: 'pointer',
    marginButtom: theme.spacing(),
  },
  logo: {
    maxWidth: '100%',
    margin: theme.spacing(3)
  }
}), { name: 'PlaidConnector' })

const PlaidConnector = (props) => {
  const {
    sessionId,
  } = props

  const classes = useStyles()
  const [linkToken, setLinkToken] = useState('')
  const [phone, setPhone] = useState('')
  const [otpOpen, setOtpOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    const fetchLinkToken = async () => {
      const tokenResponse = await request({
        url: `${REACT_APP_BASE_URL}/create-plaid-link`,
        method: 'post',
        data: plaidBody,
      })

      setLoading(false)
      setLinkToken(tokenResponse.linkToken)
    }

    fetchLinkToken()
  }, [])

  useEffect(() => {
    if (phone) {
      const sendUserOtp = async () => {
        try {
          await otpRequest({
            target: phone,
            connection: 'sms',
          })
          setOtpOpen(true)
          setLoading(false)
        }
        catch (e) {
          enqueueSnackbar('Failed to log in', { variant: 'error' })
          parentComms('auth', 'failed', e)
        }
      }

      sendUserOtp()
    }
  }, [phone])

  const onSuccess = useCallback((publicToken, metadata) => {
    const exchangePublicTokenNoId = async () => {
      setLoading(true)

      let data
      let state = 'initial'

      for (let i = 0; i < 10; i++) {
        data = await request({
          url: `${REACT_APP_BASE_URL}/exchange-plaid-token/no-identity`,
          method: 'post',
          data: {
            publicToken,
            plaidUserId,
            state,
            sessionId,
            // metadata,
          }
        }).catch((e) => e)

        state = data.state

        if (state === 'finished') i = 10
      }

      const {
        phone: userPhone,
      } = data

      setPhone(userPhone)
    }

    exchangePublicTokenNoId()
    // const exchangePublicToken = async () => {
    //   setLoading(true)

    //   let data
    //   let state = 'initial'

    //   for (let i = 0; i < 10; i++) {
    //     data = await request({
    //       url: `${REACT_APP_BASE_URL}/exchange-plaid-token`,
    //       method: 'post',
    //       data: {
    //         publicToken,
    //         plaidUserId,
    //         state,
    //         // sessionId,
    //         // metadata,
    //       }
    //     }).catch((e) => e)

    //     state = data.state

    //     if (state === 'finished') i = 10
    //   }

    //   const {
    //     phone: userPhone,
    //   } = data

    //   setPhone(userPhone)
    // }

    // exchangePublicToken()
  }, [])

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo} alt="" className={classes.logo} />
        <Typography>
          Log in with your Bank Account to continue
        </Typography>
        <Typography className={classes.disclaimer}>
          You can use your Bank Account to authanticate
        </Typography>
        <Typography className={classes.text}>
          Click here to learn more
        </Typography>
        {loading && (
          <LoaderGabi />
        )}
        {!loading && !otpOpen && (
          <>
            <PlaidLink
              token={linkToken}
              onSuccess={onSuccess}
              env={REACT_APP_PLAID_ENVIRONMENT}
              className={classes.plaidButton}
            >
              <span style={{ lineHeight: 1.75 }}>
                Open Plaid link
              </span>
            </PlaidLink>
            <div className={classes.dividerContainer}>
              <div className={classes.border} />
              <span className={classes.content}>
                OR
              </span>
              <div className={classes.border} />
            </div>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              className={classes.submit}
              onClick={() => history.push(`/?sessionId=${sessionId}`)}
            >
              Go Back
            </Button>
          </>
        )}
        {otpOpen && (
          <Otp
            setOtpOpen={setOtpOpen}
            identifier={phone}
            connection="sms"
            sessionId={sessionId}
            isSignUp
          />
        )}
      </div>
    </Container>
  )
}

export default PlaidConnector
