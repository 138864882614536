/* eslint-disable import/no-unresolved */
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { InputAdornment } from '@material-ui/core'
import PhoneIcon from '@material-ui/icons/Phone'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { useSnackbar } from 'notistack'
import { otpRequest } from '../../api'
import parentComms from '../../lib/parentComms'
import Otp from './Otp'
import logo from '../../assets/icons/everybyte-logo.png'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  container: {
    minWidth: 400,
  },
  dividerContainer: {
    display: 'flex',
    alignItems: 'center',
    opacity: 0.5,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(),
    width: '100%',
  },
  border: {
    opacity: 0.5,
    borderBottom: '1px solid black',
    width: '100%',
  },
  content: {
    padding: '0 10px 0 10px',
  },
  text: {
    marginButtom: theme.spacing(),
  },
  logo: {
    maxWidth: '100%',
    margin: theme.spacing(3)
  }
}), { name: 'Main' })

const Main = (props) => {
  const {
    sessionId,
  } = props

  const classes = useStyles()
  const [phone, setPhone] = useState(null)
  const [otpOpen, setOtpOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const login = async (event) => {
    event.preventDefault()
    if (!phone) enqueueSnackbar('Phone cant be empty', { variant: 'error' })

    try {
      await otpRequest({
        target: phone,
        connection: 'sms',
      })
      setOtpOpen(true)
    }
    catch (e) {
      enqueueSnackbar('Failed to log in', { variant: 'error' })
      parentComms('auth', 'failed', e)
    }
  }

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo} alt="" className={classes.logo} />
        {!otpOpen && (
          <div className={classes.text}>
            <Typography>
              Log in with your Phone to continue
            </Typography>
          </div>
        )}
        {!otpOpen ? (
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              color="secondary"
              fullWidth
              id="phone"
              label="Phone Number"
              name="phone"
              autoComplete="phone"
              autoFocus
              value={phone}
              onInput={(e) => setPhone(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
              onClick={login}
            >
              Continue
            </Button>
            <div className={classes.dividerContainer}>
              <div className={classes.border} />
              <span className={classes.content}>
                OR
              </span>
              <div className={classes.border} />
            </div>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              className={classes.submit}
              onClick={() => history.push(`/plaid?sessionId=${sessionId}`)}
            >
              Sign up with your Bank
            </Button>
          </form>
        ) : (
          <Otp
            identifier={phone}
            connection="sms"
            setOtpOpen={setOtpOpen}
            sessionId={sessionId}
          />
        )}
      </div>
    </Container>
  )
}

export default Main
